.chartFlex {
  display: flex;
}

.charts {
  width: calc(70% - 30px);
  margin-right: 30px;
}

.chart {
  margin-top: 30px;
  width: 100%;
}

.chartWidth {
  width: 100%;
}

.radiosBlock {
  height: auto;
  width: 30%;
  padding-left: 30px;
  border-left: 1px solid #CDD5DF;
}

.radioButton {
  margin-bottom: 10px;
}

.formGroup {
  margin-top: 24px;
}

.radioLabel {
  margin-bottom: 16px;
}