.searchInput {
	&__root {
		position: relative;
	}

	&__btn {
		position: absolute;
		top: -5px;
		right: -2px;
		width: 32px;
		height: 28px;
	}
}
