@import 'styles/variables';

.root {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 32px;
	padding: 0 24px;
	background-color: $blue_4;

	&__addBtn {
		border-radius: 30px !important;
		border: 1px $purple_5 solid !important;
		text-transform: none !important;
		font-size: 12px !important;
		line-height: 22px !important;
		color: $gray_9 !important;
		background-color: $gray_1 !important;
		padding: 1px 18px !important;
		transition: 0.2s !important;

		&:hover {
			border-color: $purple_7 !important;
		}
	}

	&__backBtn {
		// margin-left: 8px !important;
		// margin-right: 24px !important;
	}

	&__name {
		color: $gray_1 !important;
		margin-right: auto;
	}

	&__users {
		color: $gray_1;

		&:hover,
		&:active {
			color: $gray_1;
		}
	}
}
