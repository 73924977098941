@import 'styles/variables';

.root {
    display: flex;
    align-items: center;
}

.indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
}

.label {
    color: $gray_9;
}
