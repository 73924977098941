.accordeon {
	width: 100%;
}

.titleWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.titleLeft {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.iconRight {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	cursor: pointer;
}

.icon {
	cursor: pointer;
	height: 18px;
	width: 18px;
	transition: transform 0.2s ease-in;
	& svg {
		width: 100%;
		height: 100%;
	}
}

.iconUp {
	cursor: pointer;
	height: 18px;
	width: 18px;
	transition: transform 0.2s ease-in;
	transform: rotate(-180deg);
	& svg {
		width: 100%;
		height: 100%;
	}
}

.content {
	margin-top: 16px;
}

.title {
	color: #9254DE !important;
	font-weight: 500;
	margin-left: 8px;
}
