.dropdown {
	display: none;
}

.shown {
	position: absolute;
	right: 0;
	top: 100%;
	left: auto;
	width: 280px;
	min-width: 280px;
	max-width: 280px;
	border-radius: 5px;
	z-index: 100;
	list-style: none;
	background-color: #fff;
	padding: 0px;
	display: block;
	box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
		0px 3px 6px -4px rgba(0, 0, 0, 0.12);
	transition: all 0.3s ease;
}

.positionLeft {
	left: 0;
	right: auto;
	top: calc(100% + 8px);
}

.positionRight {
	left: auto;
	right: 0;
	top: calc(100% + 8px);
}

.positionMenuLeft {
	left: auto;
	right: 100%;
	top: 0;
}

.positionMenuRight {
	left: 100%;
	right: auto;
	top: 0;
}

.customWidth {
	width: 100%;
}

.smallWidth {
	width: 240px;
	min-width: 240px;
	max-width: 240px;
}
.extraSmallWidth {
	width: 160px;
	min-width: 160px;
	max-width: 160px;
}
