.question {
    &__wrapper {
        display: flex;
        align-items: center;

        &:last-child {
            border-bottom: none;
        }
    }

    &__title {
        display: flex;
        align-items: center;

        justify-content: space-between;
        color: #A3AEBE;
        margin-left: 8px;
    }
}
