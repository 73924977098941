.page {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 24px 0;
}

.content {
    width: 100%;
}
