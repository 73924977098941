.typography--variant-h1 {
  font-size: 56px;
  line-height: 64px;
  font-weight: 400;
}

.typography--variant-h2 {
  font-size: 46px;
  line-height: 54px;
  font-weight: 400;
}

.typography--variant-h3 {
  font-size: 38px;
  line-height: 46px;
  font-weight: 400;
}

.typography--variant-h4 {
  font-size: 30px;
  line-height: 38px;
  font-weight: 400;
}

.typography--variant-h5 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}

.typography--variant-h6 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}


.typography--variant-subheading {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.typography--variant-body1 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.typography--variant-body2 {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

 
/* 16 - sub title 
12 caption */

.typography--variant-p {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.typography--weight-300 {
  font-weight: 300;
}

/* необязателен - по умолчанию 400 везде */
.typography--weight-400 {
  font-weight: 400;
}

.typography--weight-500 {
  font-weight: 500;
}

.typography--weight-600 {
  font-weight: 600;
}

.typography--weight-700 {
  font-weight: 700;
}