.table {
	font-size: 14px;
	line-height: 22px;
	border: 1px solid #e3e8ef;
	border-radius: 2px;
	width: 100%;
	max-width: 100%;
	& thead {
		border-radius: 2px 2px 0 0;
	}
	& tr {
		height: 54px;
		min-height: 54px;
	}
}
.headerRow {
	border-radius: 2px 2px 0 0;
}
.headCell {
	background: #f8fafc;
	font-weight: 600;
	padding: 16px;
	text-align: left;
	&:first-child {
		border-radius: 2px 0 0 2px;
	}
	&:last-child {
		border-radius: 0 2px 2px 0;
	}
	&Sort {
		background: #f8fafc;
		font-weight: 600;
		padding: 16px;
		text-align: left;
		display: flex;
		align-items: center;
		&:first-child {
			border-radius: 2px 0 0 2px;
		}
		&:last-child {
			border-radius: 0 2px 2px 0;
		}
	}
}

.headCellSortIcon {
	margin-left: 8px;
	display: flex;
}

.bodyRow {
	border-top: 1px solid #e3e8ef;
	background-color: #fff;
}
.bodyCell {
	padding: 16px;
}

.bodyCellSmall {
	padding: 11px 16px;
}
