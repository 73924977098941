.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  height: 99vh;
}

.wrapperImg {
	margin-bottom: 20px;
  & img {
    width: 300px;
  }
}
