@import 'styles/variables';
@import 'styles/mixins';

.menu {
	width: 280px;
	margin-right: 20px;
	height: 100%;
	flex-shrink: 0;
}

.actions {
	position: absolute !important;
	top: 50% !important;
	right: 24px !important;
	transform: translateY(-50%) !important;
	z-index: 10;

	@include resetBtnDefaultStyles;
}

.header {
	height: 72px;
	position: relative;
	padding: 16px 24px 10px;
	border-left: 2px solid #eef2f6;

	&::after {
		display: block;
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		height: 1px;
		width: 100%;
		background-color: $gray_4;
	}

	&Name {
		width: 200px;
		font-size: 16px !important;
		font-weight: 600 !important;
		line-height: 24px !important;
		color: $gray_9 !important;

		@include ellipsis;
	}

	&Login {
		width: 200px;
		font-size: 14px !important;
		font-weight: 400 !important;
		line-height: 22px !important;
		color: $gray_9 !important;

		@include ellipsis;
	}
}

.list::before {
	display: block;
	content: '';
	width: 100%;
	height: 18px;
	border-left: 2px solid #eef2f6;
}

.list::after {
	display: block;
	content: '';
	width: 100%;
	height: 16px;
	border-left: 2px solid #eef2f6;
}

.menuItem {
	width: 100%;
	cursor: pointer;
	border-left: 2px solid #eef2f6;
	padding: 8px 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #738094;

	&.active {
		border-color: #9254de;
		color: #9254de;
	}

	.devIndicator {
		background-color: #9254de;
		border-radius: 20px;
		padding: 0 7px;
		color: #fff;
		font-size: 8px;
	}

	.menuItemText {
		color: #738094 !important;
		font-size: 14px !important;
		font-weight: 400 !important;
		line-height: 22px !important;
		margin-right: 10px !important;
	}

	.activeMenuItemText {
		color: #9254de !important;
		font-weight: 700 !important;
	}
}

.menuItemFirst {
	padding-top: 18px;
}

.menuItemLast {
	padding-bottom: 16px;
}

.content {
	display: flex;
	justify-content: flex-start;
}
