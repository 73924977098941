.form {
	position: relative;
	padding: 0 0 56px;
	min-height: 227px;
}

.toggleItem {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 5px 0;
	padding-right: 16px;
}

.content {
	max-height: 600px;
	overflow-y: auto;
}

.footer {
	position: absolute;
	bottom: 0;
	left: 0;
}

.loader {
	padding-top: 24px;
}

.search {
	margin-bottom: 16px;
}
