.root {
	display: flex;
	align-items: center;
	margin: 30px 0;

	.paginationBox {
		display: flex;
		align-items: center;

		.paginationWindow {
			display: flex;
			align-items: center;

			.paginationWindowInput,
			.paginationWindowInputDisabled {
				width: 47px;
				height: 30px;
				font-size: 14px;
				line-height: 22px;
				border: 1px solid #cdd5df;
				border-radius: 5px;
				padding: 0 5px;
				transition: 0.3s;
				margin-right: 8px;
				color: #738094;
				text-align: center;

				&:hover {
					border-color: #9254de;
				}
			}

			.paginationWindowInputDisabled {
				background: #eef2f6;
				pointer-events: none;
				cursor: default;
				opacity: 0.38;
			}

			.button,
			.buttonDisabled {
				width: 34px;
				min-width: 34px !important;
				height: 32px;
				border-radius: 5px;
				padding: 5px !important;
				background-color: #9254de !important;
			}

			.buttonDisabled {
				pointer-events: none;
				cursor: default;
				opacity: 0.38;
			}
		}
	}
}
